import request from './../request.js'

export function listDataUrl(obj){  //表格列表
    let params = {}
    params.importType = 1
    return request({
        url: 'card/importcard/list.do?',
        method: 'post',
        data: Object.assign(params, obj)
    })
}
export function getAccountList(){ //运营商
    let params = {}
    params.jihuotype = 2
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data:params
    })
}

export function getAgentForImportCard(){  //代理商
    let params = {}
    params.jihuotype = 2
    return request({
        url: 'agent/agent4importcard.do?',
        method: 'post',
        data:params
    })
}
export function gridAgentSetMealForImportCard(obj){ //运营商和代理商决定套餐
    let params = {}
    params.jihuotype = 2
    params.isRealName = 2
    params.isAgentRecharge = 2
    params.importNoLoopCard = true
    return request({
        url: 'meal/agentsetmeal/gridForImportCard.do?',
        method: 'post',
        data:Object.assign(params, obj)
    })
}
export function gridSetMealForImportCard(obj){ //运营商
    let params = {}
    params.jihuotype = 2
    params.isRealName = 2
    params.isAgentRecharge = 2
    params.importNoLoopCard = true
    return request({
        url: 'meal/setmeal/gridForImportCard.do?',
        method: 'post',
        data:Object.assign(params, obj)
    })
}
export function importSync(params){
    return request({
        url: 'card/importcard/importSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function importCardSync(params){
    return request({
        url: 'card/importcardaudit/importCardSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

//failList.vue
export function importListFail(params){
    return request({
        url: 'card/importcardfail/list.do?',
        method: 'post',
        data:params
    })
}
export function findById(params){
    return request({
        url: 'card/importcardfail/findById.do?',
        method: 'post',
        data:params
    })
}